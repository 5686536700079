<template>
  <div id="error">
    <NuxtLayout>
      <div class="errorSection">
        <Alert
          class="my-8 kft-alert--info"
          :title="$t('general.404page.errorAlert.title')"
          :content="$t('general.404page.errorAlert.message')"
        />
        
        <div>
          <hr class="my-10" />
          <LazyProductShowcase
            loadingl="loading"
            :products="topEvents"
            :query-id="topEventsQueryID"
            :tags="[]"
            :title="$t('general.404page.eventSuggestions.title')"
            :title-h-class="`h5`"
            :title-level="2"
            algolia-insights-label="Top Events Suggestions - 404 page"
            class="suggestions-product-showcase py-8"
          />
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'
import { useHomepageAlgolia } from '~/composables/useHomepageAlgolia'
import { eventSectionComposableFactory } from '~/composables/algolia/states'
const { error = {} } = defineProps({
  error: Object as () => NuxtError,
})

const { algoliaResults, pending } = await useHomepageAlgolia()
const useTopEvents = eventSectionComposableFactory(0)

const { data: topEvents, queryId: topEventsQueryID } =
  useTopEvents(algoliaResults)

const handleError = () => clearError({ redirect: '/' })

const { t } = useI18n()

useHead({
  title: t('general.titles.error'),
})
</script>
