import revive_payload_client_tc4TcNRfgA from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.44_eslint@8.57.0_ioredis@5.4.1_magicast@0_zd5l3rsot6mj2fiwk6j3dxy2bm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KD0YEfQAFz from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.44_eslint@8.57.0_ioredis@5.4.1_magicast@0_zd5l3rsot6mj2fiwk6j3dxy2bm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CNCNt6Jloi from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.44_eslint@8.57.0_ioredis@5.4.1_magicast@0_zd5l3rsot6mj2fiwk6j3dxy2bm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_BVziYjf4TG from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.44_eslint@8.57.0_ioredis@5.4.1_magicast@0_zd5l3rsot6mj2fiwk6j3dxy2bm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_sgyRj1sHiQ from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.44_eslint@8.57.0_ioredis@5.4.1_magicast@0_zd5l3rsot6mj2fiwk6j3dxy2bm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_WHMdA9sJVf from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.44_eslint@8.57.0_ioredis@5.4.1_magicast@0_zd5l3rsot6mj2fiwk6j3dxy2bm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_u9VcpRFEii from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.44_eslint@8.57.0_ioredis@5.4.1_magicast@0_zd5l3rsot6mj2fiwk6j3dxy2bm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_zHX58fvIMx from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.44_eslint@8.57.0_ioredis@5.4.1_magicast@0_zd5l3rsot6mj2fiwk6j3dxy2bm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_dnYhSMzMmP from "/app/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_@vue+server-renderer@3.4.38_vue@3.4.38_typescript@5.5.4___magicast@0.3_bfihoopudioxdk5vvlpd257g3a/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_3AVawj9D3b from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node_ngbxmdvdrqhnccos3hkjoxh4xi/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_V2LxghPSeo from "/app/node_modules/.pnpm/@nuxtjs+i18n-edge@8.3.1-28565891.16f9309_magicast@0.3.5_rollup@4.22.5_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/i18n.mjs";
import plugin_mFTDKZnScm from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.5_rollup@4.22.5/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_tc4TcNRfgA,
  unhead_KD0YEfQAFz,
  router_CNCNt6Jloi,
  payload_client_BVziYjf4TG,
  navigation_repaint_client_sgyRj1sHiQ,
  check_outdated_build_client_WHMdA9sJVf,
  chunk_reload_client_u9VcpRFEii,
  components_plugin_KR1HBZs4kY,
  prefetch_client_zHX58fvIMx,
  plugin_dnYhSMzMmP,
  plugin_3AVawj9D3b,
  i18n_V2LxghPSeo,
  plugin_mFTDKZnScm,
  sentry_client_shVUlIjFLk
]