import validate from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.44_eslint@8.57.0_ioredis@5.4.1_magicast@0_zd5l3rsot6mj2fiwk6j3dxy2bm/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45redirect_45to_45legacy_45app_45global from "/app/middleware/1.redirect-to-legacy-app.global.ts";
import _2_45redirect_45from_45json_45global from "/app/middleware/2.redirect-from-json.global.ts";
import _3_45gtm_45global from "/app/middleware/3.gtm.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.44_eslint@8.57.0_ioredis@5.4.1_magicast@0_zd5l3rsot6mj2fiwk6j3dxy2bm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45redirect_45to_45legacy_45app_45global,
  _2_45redirect_45from_45json_45global,
  _3_45gtm_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "event-old-permalinks": () => import("/app/middleware/event-old-permalinks.ts")
}