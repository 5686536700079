import type { MultipleQueriesResponse } from '@algolia/client-search'
import { HITS_PER_SHOWCASE } from './algolia/constants'
import type { AlgoliaEventItem } from '~/composables/types/algolia'
export { useAlgoliaIndexNames } from './algolia/useAlgoliaIndexNames'

export const useHomepageAlgolia = async () => {
  const searchClient = useAlgoliaRef()
  const { locale } = useI18n()

  const homePageQueryParams = [
    {
      indexName: getTopEventListIndexName(locale.value),
      hitsPerPage: HITS_PER_SHOWCASE,
    },
    {
      indexName: getEventListSortByDateDescIndexName(locale.value),
      hitsPerPage: HITS_PER_SHOWCASE,
    },
    {
      indexName: getTopGiftIdeasIndexName(locale.value),
      hitsPerPage: HITS_PER_SHOWCASE * 2,
    },
  ]

  const homePageQueries = homePageQueryParams.map((params) => ({
    ...params,
    clickAnalytics: true,
    filters: 'has_events:true',
    aroundLatLngViaIP: process.client,
    aroundRadius: 'all',

    aroundPrecision: [
      { from: 0, value: 15000 },
      { from: 30000, value: 30000 },
      { from: 150000, value: 100000 },
    ],
  }))

  const {
    data: algoliaResults,
    pending,
    status,
  } = await useLazyAsyncData<MultipleQueriesResponse<AlgoliaEventItem>>(
    `algoliaResults-homepage-${process.client}`,
    () => {
      return searchClient.search(homePageQueries)
    }
  )

  return {
    algoliaResults,
    pending,
    status,
  }
}
